import {getPathPrefix, getRecords, postRecord} from "@/services/api";

export function getSelectableReceiptRoles(filterQuery, options = {}) {
    filterQuery.selectable = true
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'reservations/receipts/roles.json', filterQuery, options)
}

export function getReceipts(reservation_id, filterQuery, options = {}) {
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'reservations/' + reservation_id + '/receipts.json', filterQuery, options)
}

export function postReceipts(reservation_id, data, options = {}) {
    return postRecord('/prologistics/dashboard/' + getPathPrefix() + 'reservations/' + reservation_id + '/receipts.json', data, options)
}