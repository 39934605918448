import {getPathPrefix, getRecord, getRecords, postRecord, putRecord, removeRecord} from "@/services/api";

export function getTransfers(filterQuery, options = {}) {
    filterQuery.datatable = true
    if (options.toggleProgressSpinner !== true) options.toggleProgressSpinner = false
    return getRecords('/prologistics/dashboard/' + getPathPrefix() + 'transfers.json', filterQuery, options)
}

export function getTransfer(id, filterQuery = {}, options = {}) {
    return getRecord('/prologistics/dashboard/' + getPathPrefix() + 'transfers/' + id + '.json', filterQuery, options)
}

export function postTransfer(data, options = {}) {
    return postRecord('/prologistics/dashboard/' + getPathPrefix() + 'transfers.json', data, options)
}

export function putTransfer(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/' + getPathPrefix() + 'transfers/' + id + '.json', data, options)
}

export function restoreTransfer(id, data, options = {}) {
    return putRecord('/prologistics/dashboard/' + getPathPrefix() + 'transfers/' + id + '/restore.json', data, options)
}

export function removeTransfer(id, options = {}) {
    return removeRecord('/prologistics/dashboard/' + getPathPrefix() + 'transfers/', id, options)
}
